import Settings from '@material-ui/icons/Settings';
import Integrity from '@material-ui/icons/PermDataSetting';
import PeopleIcon from '@material-ui/icons/People';
import VerifiedUser from '@material-ui/icons/VerifiedUser';

import { BasePage, OverviewContainer } from 'altus-ui-components';

import routePaths from 'app/routePaths';
import { cheetahJobSourceToString } from 'mappers';
import { SystemPermission } from 'app/app.constants';
import { CheetahJobSource } from 'features/projects/dashboard/dashboard.constants';

import RoutesContainer from 'app/components/RoutesContainer';

import DashboardContainer from 'features/projects/dashboard/DashboardContainer';
import ProjectDashboardContainer from 'features/projects/dashboard/ProjectDashboardContainer';
import SimulationDashboardContainer from 'features/projects/dashboard/components/dashboards/SimulationDashboardContainer';

import UserAccessContainer from 'features/settings/userAccess/UserAccessContainer';
import SettingsOverviewContainer from 'features/settings/SettingsOverviewContainer';
import AccessRolesContainer from 'features/settings/accessRoles/AccessRolesContainer';
import ProjectRoleOverview from 'features/settings/accessRoles/projectRoles/ProjectRoleOverview';
import ProjectRoleContainer from 'features/settings/accessRoles/projectRoles/ProjectRoleContainer';
import ProjectRolesContainer from 'features/settings/accessRoles/projectRoles/ProjectRolesContainer';
import ProjectRoleBreadcrumb from 'features/settings/accessRoles/projectRoles/ProjectRoleBreadcrumb';
import ProjectRoleAccessRights from 'features/settings/accessRoles/projectRoles/ProjectRoleAccessRights';

import SystemRoutesContainer from 'app/components/SystemRoutesContainer';
import SystemRoleUsers from 'features/settings/accessRoles/systemRoles/SystemRoleUsers';
import SystemRoleOverview from 'features/settings/accessRoles/systemRoles/SystemRoleOverview';
import SystemRoleContainer from 'features/settings/accessRoles/systemRoles/SystemRoleContainer';
import SystemRoleBreadcrumb from 'features/settings/accessRoles/systemRoles/SystemRoleBreadcrumb';
import SystemRolesContainer from 'features/settings/accessRoles/systemRoles/SystemRolesContainer';
import SystemRoleAccessRights from 'features/settings/accessRoles/systemRoles/SystemRoleAccessRights';

import TaskDetailsContainer from 'features/projects/tasks/task/details/TaskDetailsContainer';
import TaskActivitiesContainer from 'features/projects/tasks/task/activities/TaskActivitiesContainer';
import TaskSimulationsContainer from 'features/projects/tasks/task/simulation/TaskSimulationsContainer';
import TaskToolstringsContainer from 'features/projects/tasks/task/toolstring/TaskToolstringsContainer';
import TaskSimulationContainer from 'features/projects/tasks/task/simulation/components/TaskSimulationContainer';
import TaskToolstringContainer from 'features/projects/tasks/task/toolstring/components/TaskToolstringContainer';
import TaskToolstringEditorContainer from 'features/projects/tasks/task/toolstring/components/edit/TaskToolstringEditorContainer';

import WellboreContainer from 'features/wells/WellboreContainer';
import WellEventsContainer from 'features/wells/events/WellEventsContainer';
import WellboreBreadcrumb from 'features/projects/components/WellboreBreadcrumb';
import WellOverviewContainer from 'features/wells/overview/WellOverviewContainer';
import WellboreFilesContainer from 'features/wells/components/WellboreFilesContainer';
import WellboreDetailsContainer from 'features/wells/details/WellboreDetailsContainer';
import WellboreSectionsContainer from 'features/wells/sections/WellboreSectionsContainer';
import WellboreElementsContainer from 'features/wells/elements/WellboreElementsContainer';

import TaskSimulationFluidsContainer from 'features/projects/tasks/task/simulation/fluids/TaskSimulationFluidsContainer';
import TaskSimulationCommentContainer from 'features/projects/tasks/task/simulation/components/TaskSimulationCommentContainer';
import TaskSimulationOptionsContainer from 'features/projects/tasks/task/simulation/components/TaskSimulationOptionsContainer';
import TaskSimulationParametersContainer from 'features/projects/tasks/task/simulation/parameters/TaskSimulationParametersContainer';
import TaskSimulationTemperatureContainer from 'features/projects/tasks/task/simulation/components/TaskSimulationTemperatureContainer';
import TaskSimulationWellboreSectionsContainer from 'features/projects/tasks/task/simulation/components/TaskSimulationWellboreSectionsContainer';
import TaskSimulationDynamicToolParametersContainer from 'features/projects/tasks/task/simulation/dynamicToolParameters/TaskSimulationDynamicToolParametersContainer';

import TasksContainer from 'features/projects/tasks/TasksContainer';
import TaskContainer from 'features/projects/tasks/task/TaskContainer';
import EventsContainer from 'features/projects/events/EventsContainer';
import TaskBreadcrumb from 'features/projects/components/TaskBreadcrumb';
import ProjectRoutesContainer from 'app/components/ProjectRoutesContainer';
import ProjectBreadcrumb from 'features/projects/components/ProjectBreadcrumb';
import FacilityDocumentsContainer from 'features/facilities/documents/FacilityDocumentsContainer';
import ProjectWellboreContainer from 'features/projects/wellbore/ProjectWellboreContainer';
import ProjectWellboreOverview from 'features/projects/wellbore/survey/ProjectWellboreOverview';
import ProjectWellboreDetailsContainer from 'features/projects/wellbore/details/ProjectWellboreDetailsContainer';
import ProjectWellboreSectionsContainer from 'features/projects/wellbore/sections/ProjectWellboreSectionsContainer';
import ProjectWellboreDetailFormContainer from 'features/projects/wellbore/details/ProjectWellboreDetailFormContainer';

import ExecutionRootContainer from 'features/projects/execution/ExecutionRootContainer';
import ExecutionIndexContainer from 'features/projects/execution/ExecutionIndexContainer';
import ExecutionSimulationContainer from 'features/projects/execution/tabs/simulation/ExecutionSimulationContainer';
import ExecutionSimulationIndexContainer from 'features/projects/execution/tabs/simulation/ExecutionSimulationIndexContainer';
import ExecutionSurfaceEquipmentContainer from 'features/projects/execution/tabs/surfaceequipment/ExecutionSurfaceEquipmentContainer';
import ExecutionActivitiesContainer from 'features/projects/execution/tabs/activities/ExecutionActivitiesContainer';
import ExecutionToolStringContainer from 'features/projects/execution/tabs/toolstring/ExecutionToolStringContainer';

import OperationsContainer from 'features/operations/OperationsContainer';
import OperationsOverviewContainer from 'features/operations/OperationsOverviewContainer';

import DataExchangeBasePage from 'features/data-exchange/DataExchangeBasePage';
import DataExchangeIcon from 'app/components/ApplicationHeader/DataExchangeIcon';
import DataExchangeContainer from 'features/data-exchange/DataExchangeContainer';
import ArchivedWinchContainer from 'features/data-exchange/winch/ArchivedWinchContainer';
import AvailableWinchContainer from 'features/data-exchange/winch/AvailableWinchContainer';
import LiveProjectContainer from 'features/data-exchange/live-projects/LiveProjectContainer';
import ArchivedWarriorContainer from 'features/data-exchange/warrior/ArchivedWarriorContainer';
import AvailableWarriorContainer from 'features/data-exchange/warrior/AvailableWarriorContainer';
import ConfigurationWinchContainer from 'features/data-exchange/winch/ConfigurationWinchContainer';
import ArchivedCheetahJobsContainer from 'features/data-exchange/cheetah/ArchivedCheetahContainer';
import AvailableCheetahJobsContainer from 'features/data-exchange/cheetah/AvailableCheetahContainer';
import CheetahJobCurvesContainer from 'features/data-exchange/live-projects/CheetahJobCurvesContainer';
import LiveProjectOverviewContainer from 'features/data-exchange/live-projects/LiveProjectOverviewContainer';
import {
  ArchivedLiveProjectsPaginatedContainer,
  AvailableLiveProjectsPaginatedContainer,
} from 'features/data-exchange/live-projects/LiveProjectsPaginated';

import FacilitiesContainer from 'features/facilities/FacilitiesContainer';
import FacilitiesProjects from 'features/facilities/projects/FacilitiesProjects';
import FacilityContainer from 'features/wells/FacilityContainer';
import FacilityOverview from 'features/facilities/overview/FacilityOverview';
import FacilityDetailsContainer from 'features/facilities/details/FacilityDetailsContainer';

import EquipmentContainer from 'features/equipment/EquipmentContainer';
import EquipmentRoutingContainer from 'features/equipment/EquipmentRoutingContainer';
import EquipmentDetailsContainer from 'features/equipment/details/EquipmentDetailsContainer';
import ToolProjectHistoryContainer from 'features/equipment/projectHistory/ToolProjectHistoryContainer';
import SurfaceEquipmentDetails from 'features/equipment/details/SurfaceEquipmentDetails';
import ProjectDetailsDescriptionContainer from 'features/projects/details/components/ProjectDetailsDescriptionContainer';
import ProjectReportLessonContainer from 'features/projects/components/ProjectReportLessonContainer';
import TaskSurfaceEquipmentContainer from 'features/projects/tasks/task/surfaceEquipment/TaskSurfaceEquipmentContainer';
import AssetHistoryContainer from 'features/equipment/assets/AssetHistoryContainer';
import CableDetails from 'features/equipment/details/CableDetails';
import DataAcquisitionDataFetchWrapper from 'features/projects/tasks/task/dataAcquisition/DataAcquisitionDataFetchWrapper';
import { EquipmentType } from '../features/equipment/equipment.constants';
import MobilisationContainer from 'features/projects/mobilisation/MobilisationContainer';
import FieldOverviewContainer from 'features/fields/overview/FieldOverviewContainer';
import FieldRoutingContainer from 'features/fields/FieldRoutingContainer';
import FieldDocumentsContainer from 'features/fields/documents/FieldDocumentsContainer';

import IntegrityManagementContainer from 'features/settings/integrityManagement/IntegrityManagementContainer';
import WellboreIntegrityContainer from 'features/wells/integrity/WellboreIntegrityContainer';
import ConsequenceOfFailureContainer from 'features/settings/integrityManagement/riskPrinciples/ConsequenceOfFailureContainer';
import LikelihoodOfFailureContainer from 'features/settings/integrityManagement/riskPrinciples/LikelihoodOfFailureContainer';
import AddRuleOrCategoryContainer from 'features/settings/integrityManagement/rules/AddRuleOrCategoryContainer';
import AddRuleContainer from 'features/settings/integrityManagement/rules/rule/AddRuleContainer';
import AddCategoryContainer from 'features/settings/integrityManagement/rules/category/AddCategoryContainer';
import WellLikelihoodOfFailureContainer from 'features/wells/integrity/WellLikelihoodOfFailureContainer';
import WellConsequenceContainer from 'features/wells/integrity/WellConsequenceContainer';

// TODO: Replace BasePages with proper components
export const dataExchangeRoutes = [
  {
    hidden: true,
    Icon: DataExchangeIcon,
    breadcrumb: 'Data Exchange',
    component: DataExchangeContainer,
    path: routePaths.dataExchange.root,
    systemPermissions: [SystemPermission.DATA_EXCHANGE],
    routes: [
      {
        exact: true,
        hidden: true,
        component: OverviewContainer,
        path: routePaths.dataExchange.root,
      },
      {
        path: routePaths.dataExchange.cheetah.root,
        breadcrumb: 'Cheetah',
        component: DataExchangeBasePage,
        routes: [
          {
            exact: true,
            breadcrumb: 'Available',
            path: routePaths.dataExchange.cheetah.root,
            component: AvailableCheetahJobsContainer,
          },
          {
            breadcrumb: 'Archived',
            path: routePaths.dataExchange.cheetah.archived,
            component: ArchivedCheetahJobsContainer,
          },
        ],
      },
      {
        path: routePaths.dataExchange.winch.root,
        breadcrumb: 'Winch',
        component: DataExchangeBasePage,
        routes: [
          {
            exact: true,
            breadcrumb: 'Available',
            path: routePaths.dataExchange.winch.root,
            component: AvailableWinchContainer,
          },
          {
            breadcrumb: 'Archived',
            path: routePaths.dataExchange.winch.archived,
            component: ArchivedWinchContainer,
          },
          {
            breadcrumb: 'Configuration',
            path: routePaths.dataExchange.winch.configuration,
            component: ConfigurationWinchContainer,
          },
        ],
      },
      {
        path: routePaths.dataExchange.warrior.root,
        breadcrumb: 'Warrior',
        component: DataExchangeBasePage,
        routes: [
          {
            exact: true,
            breadcrumb: 'Available',
            path: routePaths.dataExchange.warrior.root,
            component: AvailableWarriorContainer,
          },
          {
            breadcrumb: 'Archived',
            path: routePaths.dataExchange.warrior.archived,
            component: ArchivedWarriorContainer,
          },
        ],
      },
      {
        hidden: true,
        breadcrumb: ProjectBreadcrumb,
        component: LiveProjectContainer,
        path: routePaths.dataExchange.liveProjects.project.root,
        routes: [
          {
            exact: true,
            breadcrumb: 'Overview',
            path: routePaths.dataExchange.liveProjects.project.root,
            component: LiveProjectOverviewContainer,
          },
          {
            breadcrumb: 'Master Data',
            path: routePaths.dataExchange.liveProjects.project.masterData,
            component: ({ breadcrumb }) => (
              <BasePage title={breadcrumb}>Not yet implemented</BasePage>
            ),
          },
          {
            breadcrumb: cheetahJobSourceToString(CheetahJobSource.CHEETAH),
            path: routePaths.dataExchange.liveProjects.project.cheetah.match,
            component: CheetahJobCurvesContainer,
            source: CheetahJobSource.CHEETAH,
          },
          {
            breadcrumb: cheetahJobSourceToString(CheetahJobSource.SM_LOCATION),
            path: routePaths.dataExchange.liveProjects.project.winch.match,
            component: CheetahJobCurvesContainer,
            source: CheetahJobSource.SM_LOCATION,
          },
          {
            breadcrumb: cheetahJobSourceToString(CheetahJobSource.WARRIOR),
            path: routePaths.dataExchange.liveProjects.project.warrior.match,
            component: CheetahJobCurvesContainer,
            source: CheetahJobSource.WARRIOR,
          },
        ],
      },
      {
        path: routePaths.dataExchange.liveProjects.root,
        breadcrumb: 'Live Projects',
        component: DataExchangeBasePage,
        routes: [
          {
            exact: true,
            breadcrumb: 'Available',
            path: routePaths.dataExchange.liveProjects.root,
            component: AvailableLiveProjectsPaginatedContainer,
          },
          {
            breadcrumb: 'Archived',
            path: routePaths.dataExchange.liveProjects.archived,
            component: ArchivedLiveProjectsPaginatedContainer,
          },
        ],
      },
    ],
  },
];

export const settingsRoutes = [
  {
    hidden: true,
    breadcrumb: 'Settings',
    path: routePaths.settings.root,
    Icon: Settings,
    component: SystemRoutesContainer,
    routes: [
      {
        exact: true,
        hidden: true,
        path: routePaths.settings.root,
        Icon: Settings,
        component: SettingsOverviewContainer,
      },
      {
        breadcrumbDisabled: true,
        breadcrumb: 'Access Roles',
        path: '/v2/settings/access-roles',
        Icon: VerifiedUser,
        component: AccessRolesContainer,
        systemPermissions: [
          SystemPermission.MANAGE_SYSTEM_ROLES,
          SystemPermission.MANAGE_PROJECT_ROLES,
        ],
        routes: [
          {
            breadcrumbDisabled: true,
            path: '/v2/settings/access-roles/system',
            breadcrumb: 'System',
            component: SystemRolesContainer,
            systemPermissions: SystemPermission.MANAGE_SYSTEM_ROLES,
            routes: [
              {
                breadcrumb: SystemRoleBreadcrumb,
                path: '/v2/settings/access-roles/system/:systemRoleId',
                component: SystemRoleContainer,
                routes: [
                  {
                    exact: true,
                    breadcrumb: 'Overview',
                    path: '/v2/settings/access-roles/system/:systemRoleId',
                    component: SystemRoleOverview,
                  },
                  {
                    exact: true,
                    breadcrumb: 'Access Rights',
                    path: '/v2/settings/access-roles/system/:systemRoleId/access-rights',
                    component: SystemRoleAccessRights,
                  },
                  {
                    exact: true,
                    breadcrumb: 'Users',
                    path: '/v2/settings/access-roles/system/:systemRoleId/users',
                    component: SystemRoleUsers,
                    systemPermissions: SystemPermission.MANAGE_USER_ACCESS,
                  },
                ],
              },
            ],
          },
          {
            breadcrumbDisabled: true,
            path: '/v2/settings/access-roles/project',
            breadcrumb: 'Project',
            component: ProjectRolesContainer,
            systemPermissions: SystemPermission.MANAGE_PROJECT_ROLES,
            routes: [
              {
                breadcrumb: ProjectRoleBreadcrumb,
                path: '/v2/settings/access-roles/project/:projectRoleId',
                component: ProjectRoleContainer,
                routes: [
                  {
                    exact: true,
                    breadcrumb: 'Overview',
                    path: '/v2/settings/access-roles/project/:projectRoleId',
                    component: ProjectRoleOverview,
                  },
                  {
                    exact: true,
                    breadcrumb: 'Access Rights',
                    path: '/v2/settings/access-roles/project/:projectRoleId/access-rights',
                    component: ProjectRoleAccessRights,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        breadcrumb: 'User Access',
        path: '/v2/settings/user-access',
        Icon: PeopleIcon,
        component: UserAccessContainer,
        systemPermissions: SystemPermission.MANAGE_USER_ACCESS,
      },
      {
        breadcrumb: 'Integrity Management',
        path: `${routePaths.settings.root}/integrity`,
        Icon: Integrity,
        component: IntegrityManagementContainer,
        systemPermissions: SystemPermission.INTEGRITY_RULES_MANAGEMENT,
        routes: [
          {
            exact: true,
            breadcrumb: 'Likelihood of Failure',
            path: routePaths.settings.integrity.root,
            component: LikelihoodOfFailureContainer,
          },
          {
            exact: true,
            breadcrumb: 'Well Consequence',
            path: routePaths.settings.integrity.consequence,
            component: ConsequenceOfFailureContainer,
          },
          {
            exact: true,
            breadcrumb: 'Add or edit integrity',
            hidden: true,
            path: routePaths.settings.integrity.new,
            component: AddCategoryContainer,
          },
          // temp until we implement both on AddRuleOrCategoryContainer
          {
            exact: true,
            breadcrumb: 'Add new rule',
            hidden: true,
            path: routePaths.settings.integrity.newRule,
            component: AddRuleContainer,
          },
          {
            exact: true,
            breadcrumb: 'Add new container',
            hidden: true,
            path: routePaths.settings.integrity.newCategory,
            component: AddRuleOrCategoryContainer,
          },
        ],
      },
    ],
  },
];

export const simulationDashboardRoutes = [
  {
    hidden: true,
    breadcrumb: 'Simulation dashboard',
    path: routePaths.dashboard.simulation,
    component: SimulationDashboardContainer,
  },
];

export const dashboardRoutes = [
  {
    path: routePaths.dashboard.root,
    component: DashboardContainer,
    routes: [
      ...simulationDashboardRoutes,
      {
        path: routePaths.dashboard.index,
        component: ProjectDashboardContainer,
      },
    ],
  },
];

export const projectWellDetailsRoutes = [
  {
    path: routePaths.projectWellbore.index,
    breadcrumb: 'Well',
    component: ProjectWellboreContainer,
    routes: [
      {
        exact: true,
        breadcrumb: 'Survey',
        path: routePaths.projectWellbore.index,
        component: ProjectWellboreOverview,
      },
      {
        hidden: true,
        breadcrumb: 'Details',
        path: routePaths.projectWellbore.details.index,
        component: ProjectWellboreDetailFormContainer,
      },
      {
        exact: true,
        breadcrumb: 'Details',
        path: routePaths.projectWellbore.details.match,
        component: ProjectWellboreDetailsContainer,
      },
      {
        breadcrumb: 'Sections',
        path: routePaths.projectWellbore.sections,
        component: ProjectWellboreSectionsContainer,
      },
    ],
  },
];

export const projectDetailsRoutes = [
  {
    exact: true,
    path: routePaths.details,
    breadcrumb: 'Details',
    component: ProjectDetailsDescriptionContainer,
  },
];

export const taskRoutes = [
  {
    exact: true,
    breadcrumb: 'Task',
    path: routePaths.plan.task.index,
    component: TaskDetailsContainer,
  },
  {
    breadcrumb: 'Surface Equipment',
    component: TaskSurfaceEquipmentContainer,
    path: routePaths.plan.task.surfaceEquipment,
  },
  {
    subSurfaceRoute: true,
    breadcrumb: 'BHA',
    component: TaskToolstringsContainer,
    path: routePaths.plan.task.toolstring.match,
    routes: [
      {
        exact: true,
        breadcrumb: 'BHA',
        component: TaskToolstringContainer,
        path: routePaths.plan.task.toolstring.index,
      },
      {
        exact: true,
        breadcrumb: 'BHA',
        path: routePaths.plan.task.toolstring.edit,
        component: TaskToolstringEditorContainer,
      },
    ],
  },
  {
    subSurfaceRoute: true,
    breadcrumb: 'Simulation',
    component: TaskSimulationsContainer,
    path: routePaths.plan.task.simulation.match,
    routes: [
      {
        breadcrumb: 'Simulation',
        component: TaskSimulationContainer,
        path: routePaths.plan.task.simulation.index,
        routes: [
          {
            exact: true,
            breadcrumb: 'Basic project options',
            component: TaskSimulationOptionsContainer,
            path: routePaths.plan.task.simulation.index,
          },
          {
            breadcrumb: 'Well Sections',
            component: TaskSimulationWellboreSectionsContainer,
            path: routePaths.plan.task.simulation.sections,
          },
          {
            breadcrumb: 'Sim. Parameters',
            component: TaskSimulationParametersContainer,
            path: routePaths.plan.task.simulation.parameters,
          },
          {
            breadcrumb: 'Temperature',
            component: TaskSimulationTemperatureContainer,
            path: routePaths.plan.task.simulation.temperature,
          },
          {
            breadcrumb: 'Fluids',
            component: TaskSimulationFluidsContainer,
            path: routePaths.plan.task.simulation.fluids.match,
          },
          {
            breadcrumb: 'Comment',
            component: TaskSimulationCommentContainer,
            path: routePaths.plan.task.simulation.comment,
          },
          {
            breadcrumb: 'Tool Parameters',
            component: TaskSimulationDynamicToolParametersContainer,
            path: routePaths.plan.task.simulation.toolParameters,
          },
        ],
      },
    ],
  },
  {
    breadcrumb: 'Activities',
    component: TaskActivitiesContainer,
    path: routePaths.plan.task.activities,
  },
];

export const wellsRoutes = [
  {
    path: '/wells',
    breadcrumb: 'Wells',
  },
  {
    hidden: true, // do not display in navigation menu
    breadcrumb: 'Wells',
    path: '/field/:fieldId/wells',
    component: RoutesContainer,
    routes: [
      {
        exact: true,
        path: '/field/:fieldId/wells',
        redirectTo: '/wells',
      },
      {
        breadcrumb: WellboreBreadcrumb,
        component: WellboreContainer,
        path: routePaths.wells.index,
        routes: [
          {
            exact: true,
            breadcrumb: 'Overview',
            component: WellOverviewContainer,
            path: routePaths.wells.index,
          },
          {
            breadcrumb: 'Details',
            component: WellboreDetailsContainer,
            path: routePaths.wells.details,
          },
          {
            breadcrumb: 'Sections',
            component: WellboreSectionsContainer,
            path: routePaths.wells.sections,
          },
          {
            breadcrumb: 'Elements',
            component: WellboreElementsContainer,
            path: routePaths.wells.elements,
          },
          {
            breadcrumb: 'Integrity',
            component: WellboreIntegrityContainer,
            path: routePaths.wells.integrity.root,
            permission: SystemPermission.WELL_ACCESS_ONLY,
            systemPermissions: [SystemPermission.WELL_ACCESS_ONLY],
            routes: [
              {
                exact: true,
                breadcrumb: 'Likelihood of Failure',
                path: routePaths.wells.integrity.root,
                component: WellLikelihoodOfFailureContainer,
              },
              {
                exact: true,
                breadcrumb: 'Well Consequence',
                path: routePaths.wells.integrity.consequence,
                component: WellConsequenceContainer,
              },
            ],
          },
          {
            breadcrumb: 'Documents',
            component: WellboreFilesContainer,
            path: routePaths.wells.files,
          },
          {
            hidden: true,
            breadcrumb: 'Events',
            component: WellEventsContainer,
            path: routePaths.wells.events,
          },
        ],
      },
    ],
  },
];

export const operationsRoutes = [
  {
    exact: true,
    breadcrumb: 'Live Operations',
    component: OperationsContainer,
    path: routePaths.operations.root,
  },
  {
    hidden: true,
    breadcrumb: 'Live Dashboards',
    component: OperationsOverviewContainer,
    path: `${routePaths.operations.dashboards}/:projectId`,
  },
];

export const equipmentRoutes = [
  {
    exact: true,
    breadcrumb: 'Equipment',
    path: routePaths.equipment.root,
    component: EquipmentContainer,
    permission: SystemPermission.EQUIPMENT_TAB_VIEW,
    systemPermissions: [SystemPermission.EQUIPMENT_TAB_VIEW],
  },
  {
    hidden: true,
    breadcrumb: '',
    component: (props) => (
      <AssetHistoryContainer {...props} equipmentType={EquipmentType.TOOL} />
    ),
    path: routePaths.equipment.assetHistory,
  },
  {
    hidden: true,
    breadcrumb: 'Equipment',
    path: routePaths.equipment.root,
    component: RoutesContainer,
    systemPermissions: [SystemPermission.EQUIPMENT_TAB_VIEW],
    routes: [
      {
        breadcrumb: 'Equipment',
        component: EquipmentRoutingContainer,
        path: routePaths.equipment.index,
        routes: [
          {
            exact: true,
            breadcrumb: 'Details/Assets',
            component: EquipmentDetailsContainer,
            path: routePaths.equipment.index,
          },
          {
            exact: true,
            breadcrumb: 'Project History',
            component: ToolProjectHistoryContainer,
            path: routePaths.equipment.projectHistory,
          },
        ],
      },
    ],
  },
];

export const cableEquipmentRoutes = [
  {
    hidden: true,
    breadcrumb: 'Cable Equipment',
    path: routePaths.cable.root,
    component: RoutesContainer,
    systemPermissions: [SystemPermission.EQUIPMENT_TAB_VIEW],
    routes: [
      {
        exact: true,
        breadcrumb: 'Details/Assets',
        component: CableDetails,
        path: routePaths.cable.index,
      },
      {
        exact: true,
        breadcrumb: 'Asset History',
        path: routePaths.cable.assetHistory,
        component: (props) => (
          <AssetHistoryContainer
            {...props}
            equipmentType={EquipmentType.CABLE}
          />
        ),
      },
    ],
  },
];

export const surfaceEquipmentRoutes = [
  {
    hidden: true,
    breadcrumb: 'Surface Equipment',
    path: routePaths.surfaceEquipment.root,
    component: RoutesContainer,
    systemPermissions: [SystemPermission.EQUIPMENT_TAB_VIEW],
    routes: [
      {
        exact: true,
        breadcrumb: 'Details/Assets',
        component: SurfaceEquipmentDetails,
        path: routePaths.surfaceEquipment.index,
      },
      {
        exact: true,
        breadcrumb: 'Asset History',
        path: routePaths.surfaceEquipment.assetHistory,
        component: (props) => (
          <AssetHistoryContainer
            {...props}
            equipmentType={EquipmentType.SURFACE}
          />
        ),
      },
    ],
  },
];

export const facilitiesRoutes = [
  {
    exact: true,
    breadcrumb: 'Facilities',
    path: routePaths.facilities.root,
    component: FacilitiesContainer,
  },
  {
    hidden: true,
    breadcrumb: 'Facilities',
    path: '/facilities/:facilityId',
    component: RoutesContainer,
    routes: [
      {
        breadcrumb: 'Facilities',
        component: FacilityContainer,
        path: routePaths.facilities.index,
        systemPermissions: [SystemPermission.MANAGE_WELLS],
        routes: [
          {
            exact: true,
            breadcrumb: 'Overview',
            component: FacilityOverview,
            path: routePaths.facilities.index,
          },
          {
            exact: true,
            breadcrumb: 'Details',
            component: FacilityDetailsContainer,
            path: routePaths.facilities.details,
          },
          {
            exact: true,
            breadcrumb: 'Documents',
            component: FacilityDocumentsContainer,
            path: routePaths.facilities.documents,
          },
          {
            breadcrumb: 'Project History',
            component: FacilitiesProjects,
            path: routePaths.facilities.projectHistory,
          },
        ],
      },
    ],
  },
];

export const fieldRoutes = [
  {
    hidden: true,
    breadcrumb: 'Field',
    path: routePaths.field.root,
    component: RoutesContainer,
    routes: [
      {
        breadcrumb: 'Equipment',
        component: FieldRoutingContainer,
        path: routePaths.field.index,
        routes: [
          {
            exact: true,
            breadcrumb: 'Overview',
            component: FieldOverviewContainer,
            path: routePaths.field.index,
          },
          {
            exact: true,
            breadcrumb: 'Documents',
            component: FieldDocumentsContainer,
            path: routePaths.field.document,
          },
        ],
      },
    ],
  },
];

export const projectExecutionRoutes = [
  {
    path: routePaths.execution.match,
    breadcrumb: 'Execute',
    component: ExecutionRootContainer,
    routes: [
      {
        path: routePaths.execution.index,
        hidden: true,
        exact: true,
        index: true,
        breadcrumb: 'Index - No Tasks',
        component: ExecutionIndexContainer,
      },
      {
        exact: true,
        path: routePaths.execution.tabs.activities,
        breadcrumb: 'Activities',
        component: ExecutionActivitiesContainer,
      },
      {
        exact: true,
        path: routePaths.execution.tabs.surfaceEquipment,
        breadcrumb: 'Surface Equipment',
        component: ExecutionSurfaceEquipmentContainer,
      },
      {
        exact: true,
        path: routePaths.execution.tabs.toolstring,
        breadcrumb: 'BHA',
        component: ExecutionToolStringContainer,
      },
      {
        path: routePaths.execution.tabs.simulation.match,
        breadcrumb: 'Simulation',
        component: ExecutionSimulationContainer,
        routes: [
          {
            path: routePaths.execution.tabs.simulation.index,
            hidden: true,
            exact: true,
            index: true,
            breadcrumb: 'Index - No Simulations',
            component: ExecutionSimulationIndexContainer,
          },
          {
            path: routePaths.execution.tabs.simulation.tabs.options,
            exact: true,
            breadcrumb: 'Basic project options',
            component: TaskSimulationOptionsContainer,
          },
          {
            path: routePaths.execution.tabs.simulation.tabs.sections,
            exact: true,
            breadcrumb: 'Well Sections',
            component: TaskSimulationWellboreSectionsContainer,
          },
          {
            path: routePaths.execution.tabs.simulation.tabs.parameters,
            exact: true,
            breadcrumb: 'Sim. Parameters',
            component: TaskSimulationParametersContainer,
          },
          {
            path: routePaths.execution.tabs.simulation.tabs.temperature,
            exact: true,
            breadcrumb: 'Temperature',
            component: TaskSimulationTemperatureContainer,
          },
          {
            path: routePaths.execution.tabs.simulation.tabs.fluids.match,
            exact: true,
            breadcrumb: 'Fluids',
            component: TaskSimulationFluidsContainer,
          },
          {
            path: routePaths.execution.tabs.simulation.tabs.comment,
            exact: true,
            breadcrumb: 'Comment',
            component: TaskSimulationCommentContainer,
          },
          {
            path: routePaths.execution.tabs.simulation.tabs.toolParameters,
            exact: true,
            breadcrumb: 'Tool Parameters',
            component: TaskSimulationDynamicToolParametersContainer,
          },
        ],
      },

      {
        breadcrumb: 'Data Acquisition',
        component: DataAcquisitionDataFetchWrapper,
        path: routePaths.execution.tabs.dataAcquisition,
      },
    ],
  },
];

export const projectSubRoutes = [
  ...projectDetailsRoutes,
  // ...projectWellDetailsRoutes,
  ...projectExecutionRoutes,
  {
    path: routePaths.plan.index,
    component: ProjectRoutesContainer,
    routes: [
      {
        exact: true,
        path: routePaths.plan.index,
        breadcrumb: 'Plan',
        component: TasksContainer,
      },
      {
        path: routePaths.plan.task.match,
        breadcrumb: TaskBreadcrumb,
        component: TaskContainer,
        routes: taskRoutes,
      },
    ],
  },
  // {
  //   path: routePaths.execute.match,
  //   breadcrumb: 'Execute',
  //   component: ExecuteContainer,
  //   projectPermissions: ProjectPermission.EXECUTE,
  // },
  {
    path: routePaths.mobilize,
    breadcrumb: 'String Tests',
    component: MobilisationContainer,
  },
  {
    path: routePaths.report,
    breadcrumb: 'Report',
    component: ProjectReportLessonContainer,
  },
  {
    path: routePaths.report,
    breadcrumb: 'Report',
    component: ProjectReportLessonContainer,
  },
  {
    path: routePaths.events,
    breadcrumb: 'Events',
    component: EventsContainer,
  },
];

export const projectRoutes = [
  {
    breadcrumb: 'Projects',
    path: '/projects',
    routes: [
      {
        exact: true,
        path: '/projects/:projectId',
        breadcrumb: ProjectBreadcrumb,
        routes: projectSubRoutes,
      },
    ],
  },
];

const routes = [
  ...operationsRoutes,
  ...projectRoutes,
  ...facilitiesRoutes,
  ...wellsRoutes,
  ...settingsRoutes,
  ...equipmentRoutes,
  ...surfaceEquipmentRoutes,
  ...cableEquipmentRoutes,
  ...dataExchangeRoutes,
  ...fieldRoutes,
];

export default routes;
