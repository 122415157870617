import React from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Drawer } from '@material-ui/core';
import { BasePage } from 'altus-ui-components';
import AddRuleDrawerContent from 'features/settings/integrityManagement/rules/AddRuleDrawerContent';
import IntegrityTypeSelector from 'features/settings/integrityManagement/rules/IntegrityTypeSelector';
import AddRuleOrCategoryHeader from 'features/settings/integrityManagement/rules/AddRuleOrCategoryHeader';
import AddRuleOrCategoryStepper from 'features/settings/integrityManagement/rules/AddRuleOrCategoryStepper';

// TODO this is a placeholder for united creation of rules or categories
const AddRuleOrCategoryContainer = ({ classes, toggleDrawer }) => {
  return (
    <BasePage>
      <Grid item container spacing={2}>
        <Grid container>
          <AddRuleOrCategoryHeader
            title="New Rule"
            toggleDrawer={toggleDrawer}
          />
        </Grid>
        <Grid container>
          <AddRuleOrCategoryStepper />
        </Grid>
        <Grid container>
          <IntegrityTypeSelector />
        </Grid>
        <Grid container>
          <AddRuleDrawerContent toggleDrawer={toggleDrawer} />
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default AddRuleOrCategoryContainer;
