import React from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import RadioGroupFieldFormik from 'app/components/form/formik/RadioGroupFieldFormik';
import {
  BOOLEAN_DATA_VALUES_ENUM,
  RuleForm,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';

const BooleanRuleDataFormComponent = () => {
  const options = [BOOLEAN_DATA_VALUES_ENUM.YES, BOOLEAN_DATA_VALUES_ENUM.NO];

  return (
    <Grid item xs={6}>
      <Field name={RuleForm.VALUE}>
        {({ form, ...formik }) => {
          formik.field.onChange = (e) => {
            form.setFieldValue(RuleForm.VALUE, Number(e.target.value));
          };
          return (
            <RadioGroupFieldFormik
              options={options}
              form={form}
              row={false}
              {...formik}
            />
          );
        }}
      </Field>
    </Grid>
  );
};

export default BooleanRuleDataFormComponent;
