import { Fragment } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { Formik, Form } from 'formik';

import {
  Box,
  Dialog,
  Button,
  IconButton,
  DialogContent,
  Grid,
} from '@material-ui/core';

import { useToggle } from 'altus-hooks';
import { ModalHeader, ModalActions } from 'altus-ui-components';
import {
  RuleForm as RuleFormFields,
  RULE_TYPES_ENUM,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import DefaultRuleValueWrapper from 'features/settings/integrityManagement/rules/DefaultRuleValueWrapper';
import { isNil } from 'lodash';
import { useDispatch } from 'react-redux';
import { addNewIntegrityManagementWellData } from 'features/settings/integrityManagement/helpers/integrityManagement.actions';

const UpdateIntegrityDataModal = ({ wellboreId, integrity }) => {
  const [isOpen, toggleModal] = useToggle();
  const dispatch = useDispatch();

  const initialFormValues = {
    [RuleFormFields.TITLE]: integrity.title,
    [RuleFormFields.INTEGRITY_ID]: integrity.integrityId,
    [RuleFormFields.RULE_TYPE]: integrity.integrityDetailType,
    [RuleFormFields.DEFAULT_VALUE_CHECKED]: true,
    [RuleFormFields.VALUE]:
      integrity.integrityDetailType === RULE_TYPES_ENUM.RULE_PICKLIST
        ? null
        : integrity.integrityDetailType === RULE_TYPES_ENUM.RULE_BOOL
        ? isNil(integrity.value)
          ? null
          : Number(integrity.value)
        : integrity.value,
    [RuleFormFields.PICKLIST_OPTIONS]: integrity.details,
  };

  const handleSubmit = (values) => {
    dispatch(
      addNewIntegrityManagementWellData(wellboreId, values, toggleModal),
    );
  };

  return (
    <Fragment>
      <IconButton
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          toggleModal();
        }}
      >
        <EditIcon />
      </IconButton>
      <Dialog open={isOpen} onClose={toggleModal} fullWidth maxWidth="sm">
        <ModalHeader title={integrity.title} />
        <Formik
          enableReinitialize
          initialValues={initialFormValues}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <DialogContent>
                <Box paddingTop={2}>
                  <Grid item xs={12}>
                    <DefaultRuleValueWrapper isFromWell={true} />
                  </Grid>
                </Box>
              </DialogContent>
              <ModalActions>
                <Button onClick={toggleModal}>Cancel</Button>
                <Button disabled={isSubmitting} type="submit">
                  Save
                </Button>
              </ModalActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Fragment>
  );
};

export default UpdateIntegrityDataModal;
