import { useMemo } from 'react';
import { Table, TableRowActionsCell } from 'altus-ui-components';
import { Grid, Typography, IconButton } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

import { EMPTY_LIST } from 'app/app.constants';
import {
  INTEGRITY_TYPE_ENUM,
  RULE_TYPES_ENUM,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import UpdateIntegrityDataModal from './UpdateIntegrityDataModal';

const getSubRows = (row) => {
  const subRows = [...(row.categories || []), ...(row.details || [])];

  // Check if any item in subRows also contains nested categories or details
  subRows.forEach((subRow) => {
    subRow.subRows = getSubRows(subRow);
  });

  const picklistSubRow = subRows.filter(
    (row) =>
      row.score && row.integrityDetailType === RULE_TYPES_ENUM.RULE_PICKLIST,
  );

  return !picklistSubRow.length && subRows.length > 0 ? subRows : undefined;
};

const WellIntegrityTable = ({ integrities, wellboreId }) => {
  const columns = useMemo(
    () => [
      {
        id: 'expander',
        xs: 1,
        disableSortBy: true,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (!row.canExpand) return <TableRowActionsCell minItems={1} />;

          return (
            <TableRowActionsCell>
              <IconButton
                {...row.getToggleRowExpandedProps({
                  ...row.getToggleRowExpandedProps({
                    style: {
                      paddingLeft: `${1}rem`,
                    },
                  }),
                })}
              >
                {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 4,
        Header: 'Name',
        accessor: (integrity) => integrity.title,
      },
      {
        xs: 3,
        Header: 'Value',
        accessor: (integrity) => integrity.integrityType,
        Cell: ({ row }) => {
          const integrity = row.original;

          // only rules have integrityDetailType
          if (!integrity || !integrity.integrityDetailType) {
            return null;
          }

          return (
            <Grid item xs={2}>
              <Typography variant="body2">
                {integrity.value ? String(integrity.value) : '-'}
              </Typography>
            </Grid>
          );
        },
      },
      {
        xs: 1,
        id: 'edit',
        Cell: ({ row }) => {
          const integrity = row.original;

          if (
            !integrity &&
            (!integrity.integrityType || !integrity.integrityRuleType)
          ) {
            return null;
          }

          if (
            integrity.integrityType &&
            (integrity.integrityType === INTEGRITY_TYPE_ENUM.RISK_PRINCIPLE ||
              integrity.integrityType === INTEGRITY_TYPE_ENUM.CATEGORY)
          ) {
            return null;
          }

          return (
            <TableRowActionsCell minItems={1}>
              <UpdateIntegrityDataModal
                wellboreId={wellboreId}
                integrity={integrity}
              />
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 1,
        id: 'documents',
        Cell: ({ row }) => {
          const integrity = row.original;

          if (
            !integrity &&
            (!integrity.integrityType || !integrity.integrityRuleType)
          ) {
            return null;
          }

          if (
            integrity.integrityType &&
            (integrity.integrityType === INTEGRITY_TYPE_ENUM.RISK_PRINCIPLE ||
              integrity.integrityType === INTEGRITY_TYPE_ENUM.CATEGORY)
          ) {
            return null;
          }

          return (
            <Grid item>
              <IconButton color="default">
                <DescriptionIcon />
              </IconButton>
            </Grid>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [],
  );

  const buildExpandedState = (items, expanded = {}, parentIndex = '') => {
    items.forEach((item, index) => {
      const rowIndex = parentIndex ? `${parentIndex}.${index}` : `${index}`;
      expanded[rowIndex] = true;

      const subRows = getSubRows(item);
      if (subRows) {
        buildExpandedState(subRows, expanded, rowIndex);
      }
    });
    return expanded;
  };

  const initialState = useMemo(() => {
    const expandedRows = buildExpandedState(integrities || []);
    return { expanded: expandedRows };
    // eslint-disable-next-line
  }, [integrities]);

  return (
    <Table
      useExpanded
      subrowOffset={3}
      getSubRows={getSubRows}
      items={integrities ?? EMPTY_LIST}
      columns={columns}
      useGlobalFilter={true}
      noItemsMessage="There are no rules for this organization."
      initialState={initialState} // Added the initialState prop to set expanded rows
    />
  );
};

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[700],
  },
});

export default withStyles(styles)(WellIntegrityTable);
