import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import routePaths from 'app/routePaths';
import { INTEGRITY_MANAGEMENT_ACTIONS } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import integrityManagementService from 'features/settings/integrityManagement/helpers/integrityManagement.service';

export const loadIntegrityManagementIntegrities = () => (dispatch) => {
  const payload =
    integrityManagementService.getIntegrityManagementIntegrities();
  dispatch({
    type: INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES,
    payload,
  });
  return payload;
};

export const loadIntegrityManagementRules = () => (dispatch) => {
  const payload =
    integrityManagementService.getIntegrityManagementIntegrities();
  dispatch({
    type: INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_RULES,
    payload,
  });
  return payload;
};

export const deleteIntegrityManagementRule = (ruleId) => (dispatch) => {
  const payload =
    integrityManagementService.deleteIntegrityManagementRule(ruleId);
  dispatch({
    type: INTEGRITY_MANAGEMENT_ACTIONS.DELETE_INTEGRITY_MANAGEMENT_RULE,
    confirmationDialog: {
      title: 'Delete Rule',
      confirmButtonText: 'Delete',
      description: 'Are you sure you want to delete this rule?',
    },
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Deleting Rule',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Rule successfully Deleted!',
    },
    payload,
  });
  return payload;
};

export const addNewIntegrityManagementRule =
  (formValues, toggleDrawer) => (dispatch) => {
    const { ...rule } = formValues;

    const payload = integrityManagementService
      .addNewIntegrityManagementRule(rule)
      .then(() => {
        return dispatch(loadIntegrityManagementRules()).then(() => {
          toggleDrawer();
        });
      });

    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.ADD_NEW_INTEGRITY_MANAGEMENT_RULE,
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: 'Adding new Rule',
        [NOTIFICATION_VARIANTS.SUCCESS]: 'New rule successfully added!',
      },
      payload,
    });
  };

export const addNewIntegrityManagementIntegrity =
  (formValues) => (dispatch) => {
    const { ...integrity } = formValues;

    const payload = integrityManagementService
      .addNewIntegrityManagementIntegrity(integrity)
      .then(() => {
        return dispatch(loadIntegrityManagementRules()).then(() => {
          window.location = routePaths.settings.integrity.root;
        });
      });

    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.ADD_NEW_INTEGRITY_MANAGEMENT_RULE,
      notification: {
        [NOTIFICATION_VARIANTS.ERROR]: 'Error creating integrity.',
        [NOTIFICATION_VARIANTS.INFO]: 'Adding new integrity',
        [NOTIFICATION_VARIANTS.SUCCESS]: 'New integrity successfully added!',
      },
      payload,
    });
  };

export const updateIntegrityManagementIntegrity =
  (formValues) => (dispatch) => {
    const { ...integrity } = formValues;

    const payload = integrityManagementService
      .updateIntegrityManagementIntegrity(integrity)
      .then(() => {
        return dispatch(loadIntegrityManagementRules()).then(() => {
          window.location = routePaths.settings.integrity.root;
        });
      });

    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.UPDATE_INTEGRITY_MANAGEMENT_INTEGRITY,
      notification: {
        [NOTIFICATION_VARIANTS.ERROR]: 'Error updating integrity.',
        [NOTIFICATION_VARIANTS.INFO]: 'Updating new integrity',
        [NOTIFICATION_VARIANTS.SUCCESS]: 'Integrity successfully updated!',
      },
      payload,
    });
  };

export const loadIntegrityManagementIntegritiesForWell =
  (externalWellboreId) => (dispatch) => {
    const payload =
      integrityManagementService.getIntegrityManagementIntegritiesForWell(
        externalWellboreId,
      );
    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES_FOR_WELL,
      payload,
    });
    return payload;
  };

export const addNewIntegrityManagementWellData =
  (wellboreId, formValues, toggleModal) => (dispatch) => {
    const { ...integrity } = formValues;

    const payload = integrityManagementService
      .addNewIntegrityWellData(wellboreId, integrity)
      .then(() => {
        return dispatch(loadIntegrityManagementRules()).then(() => {
          toggleModal();
        });
      });

    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.ADD_NEW_INTEGRITY_MANAGEMENT_RULE_DATA,
      notification: {
        [NOTIFICATION_VARIANTS.ERROR]: 'Error updating rule data.',
        [NOTIFICATION_VARIANTS.INFO]: 'Updating rule data.',
        [NOTIFICATION_VARIANTS.SUCCESS]: 'New rule data successfully added!',
      },
      payload,
    });
  };
