import { Link } from 'react-router-dom';
import { Grid, Button, Typography } from '@material-ui/core';

import routePaths from 'app/routePaths';

// TODO this is a placeholder for united creation of rules or categories
const AddRuleOrCategoryHeader = ({ title }) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs={11}>
        <Typography variant="h6" noWrap>
          {title}
        </Typography>
      </Grid>
      <Grid container item xs={1} justifyContent="flex-end">
        <Link
          to={routePaths.settings.integrity.root}
          style={{ textDecoration: 'none' }}
        >
          <Button title="Close">Cancel</Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default AddRuleOrCategoryHeader;
