import React from 'react';
import { Grid } from '@material-ui/core';
import CategoriesTableLocation from './CategoriesTableLocation';

const CategoryLocationContainer = ({
  integrities,
  parentId,
  setParentId,
  integrityType,
}) => (
  <Grid container justifyContent="flex-start">
    <Grid item xs={6}>
      <CategoriesTableLocation
        parentId={parentId}
        setParentId={setParentId}
        integrities={integrities}
        integrityType={integrityType}
      />
    </Grid>
  </Grid>
);

export default CategoryLocationContainer;
