import React from 'react';
import { Grid } from '@material-ui/core';
import { BasePage } from 'altus-ui-components';
import { useLocation } from 'react-router-dom';
import AddRuleOrCategoryHeader from '../AddRuleOrCategoryHeader';
import { RISK_PRINCIPLE_TYPES } from '../../helpers/integrityManagement.constants';

const AddRuleContainer = () => {
  const location = useLocation();
  const { riskPrincipleType } =
    location.state || RISK_PRINCIPLE_TYPES.LIKELIHOOD_OF_FAILURE;

  return (
    <BasePage>
      <Grid item container spacing={2}>
        <Grid container>
          <AddRuleOrCategoryHeader title="New Rule" />
        </Grid>
        <Grid container>{/* <AddRuleContainer /> */}</Grid>
      </Grid>
    </BasePage>
  );
};

export default AddRuleContainer;
