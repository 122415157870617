import React, { useState } from 'react';

import { MenuItem, Select } from '@material-ui/core';
import { INTEGRITY_TYPE_SELECTOR } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';

// TODO this is a placeholder for united creation of rules or categories
const IntegrityTypeSelector = () => {
  const [selectedType, setSelectedType] = useState(
    INTEGRITY_TYPE_SELECTOR.RULE,
  );

  const handleChange = (event) => {
    setSelectedType(event.target.value);
  };

  return (
    <Select value={selectedType} onChange={handleChange}>
      {Object.entries(INTEGRITY_TYPE_SELECTOR).map(([key, value]) => (
        <MenuItem key={key} value={value}>
          {key}
        </MenuItem>
      ))}
    </Select>
  );
};

export default IntegrityTypeSelector;
