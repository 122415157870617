import { useMemo, useEffect } from 'react';
import isNil from 'lodash/isNil';
import { useFormikContext } from 'formik';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, IconButton } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import withStyles from '@material-ui/styles/withStyles';

import { Table } from 'altus-ui-components';
import { EMPTY_LIST } from 'app/app.constants';
import {
  INTEGRITY_TYPE_SELECTOR,
  CategoryForm as CategoryFormFields,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';

// get subrows for categories only
const getSubRows = (row, integrityType) => {
  let subRows;
  if (integrityType === INTEGRITY_TYPE_SELECTOR.RULE) {
    subRows = [...(row.categories || []), ...(row.details || [])];
  } else {
    subRows = [...(row.categories || [])];
  }

  // Check if any item in subRows also contains nested categories or details
  subRows.forEach((subRow) => {
    subRow.subRows = getSubRows(subRow);
  });

  return subRows.length > 0 ? subRows : undefined;
};

const CategoriesTableLocation = ({
  parentId, // id of the parent category
  integrities,
  setParentId,
  integrityType,
}) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue([CategoryFormFields.PARENT_ID], parentId);
  }, [parentId, setFieldValue]);

  const columns = useMemo(
    () => [
      {
        xs: 1,
        id: 'expander',
        Cell: ({ row }) => {
          if (!row.canExpand) return null;

          return (
            <IconButton
              {...row.getToggleRowExpandedProps()}
              title="Expand Category"
            >
              {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          );
        },
      },
      {
        xs: 9,
        Header: '',
        id: 'name',
        accessor: (category) => category.title,
      },
      {
        xs: 1,
        id: 'add',
        Cell: ({ row }) => {
          const integrity = row.original;

          // hide if selection of position was made
          if (!isNil(parentId) || !integrity.integrityType) {
            return null;
          }

          return (
            <Grid item>
              {/* try putting it into a formik field OR create a hidden one */}
              <IconButton
                color="default"
                onClick={() => setParentId(integrity.integrityId)}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          );
        },
      },
      {
        xs: 1,
        id: 'remove',
        Cell: ({ row }) => {
          const integrity = row.original;

          if (!isNil(integrity.integrityId)) {
            return null;
          }

          return (
            <Grid item>
              <IconButton color="default" onClick={() => setParentId(null)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          );
        },
      },
    ],
    [parentId, setParentId],
  );

  const buildExpandedState = (items, expanded = {}, parentIndex = '') => {
    items.forEach((item, index) => {
      const rowIndex = parentIndex ? `${parentIndex}.${index}` : `${index}`;
      expanded[rowIndex] = true;

      const subRows = getSubRows(item);
      if (subRows) {
        buildExpandedState(subRows, expanded, rowIndex);
      }
    });
    return expanded;
  };

  const initialState = useMemo(() => {
    const expandedRows = buildExpandedState(integrities || []);
    return { expanded: expandedRows };
    // eslint-disable-next-line
  }, [integrities]);

  return (
    <Table
      useExpanded
      disableSortBy
      subrowOffset={3}
      getSubRows={(row) => getSubRows(row, integrityType)}
      items={integrities || EMPTY_LIST}
      columns={columns}
      useGlobalFilter={false}
      noItemsMessage="There are no integrities for this organization."
      initialState={initialState} // Added the initialState prop to set expanded rows
    />
  );
};

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[700],
  },
});

export default withStyles(styles)(CategoriesTableLocation);
