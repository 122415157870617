import { List } from 'immutable';

export const hasEmptyIdItem = (data) => {
  // Recursive arrow function to check for empty ids
  const recursiveCheck = (items) =>
    items.some(
      (item) =>
        item.integrityId === '' ||
        item.integrityId === null ||
        (item.categories && recursiveCheck(item.categories)) ||
        (item.details && recursiveCheck(item.details)),
    );

  return recursiveCheck(data);
};

export const getCombinedTopLevelEntries = (data, targetId) => {
  // Try to find the top-level item with the targetId
  const targetItem = data.find((item) => item.integrityId === targetId);

  if (targetItem) {
    // If the targetItem is found, return its details and categories
    const combinedEntries = [
      ...targetItem.details.map((detail) => ({
        integrityId: detail.integrityId,
        integrityDetailId: detail.integrityDetailId,
        title: detail.title,
        integrityDetailType: detail.integrityDetailType,
        userId: detail.userId,
        weight: detail.weight,
        score: detail.score,
        value: detail.value,
      })),
      ...targetItem.categories.map((category) => ({
        integrityId: category.integrityId,
        title: category.title,
        weight: category.weight,
        integrityType: category.integrityType,
        userId: category.userId,
        parentId: category.parentId,
      })),
    ];

    return List(combinedEntries);
  }

  // If not found, look for a category with the targetId
  const targetCategory = data
    .flatMap((item) => item.categories)
    .find((category) => category.integrityId === targetId);

  if (!targetCategory) return List(); // Return an empty Immutable List if neither is found

  // If the target category is found, return its nested details (subRows) and categories
  const combinedEntries = [
    ...targetCategory.details.map((detail) => ({
      integrityId: detail.integrityId,
      integrityDetailId: detail.integrityDetailId,
      title: detail.title,
      integrityDetailType: detail.integrityDetailType,
      userId: detail.userId,
      weight: detail.weight,
      score: detail.score,
      value: detail.value,
    })),
    ...targetCategory.categories.map((category) => ({
      integrityId: category.integrityId,
      title: category.title,
      weight: category.weight,
      parentId: category.parentId,
    })),
  ];

  return List(combinedEntries);
};

export const removeEmptyIdItems = (data) => {
  // Filter the items, removing those with an empty or null id
  const filteredData = data
    .filter((item) => item.integrityId !== '' && item.integrityId !== null)
    .map((item) => ({
      ...item,
      // Recursively clean categories and details
      categories: item.categories
        ? removeEmptyIdItems(item.categories).toArray()
        : [],
      details: item.details ? removeEmptyIdItems(item.details).toArray() : [],
    }));

  // Return an Immutable List of the filtered data
  return List(filteredData);
};

export const removeItemsByIntegrityId = (data, idToRemove) => {
  // Filter out items with the specific integrityId and also remove categories and details
  const filteredData = data
    .filter((item) => item.integrityId !== idToRemove) // Remove the item if it matches idToRemove
    .map((item) => ({
      ...item,
      // If categories or details exist, recursively clean them by removing items with idToRemove
      categories: item.categories
        ? removeItemsByIntegrityId(item.categories, idToRemove).toArray() // Convert List to array
        : [],
      details: item.details
        ? removeItemsByIntegrityId(item.details, idToRemove).toArray() // Convert List to array
        : [],
    }));

  // Return an Immutable List of the filtered data, top-level only
  return List(filteredData);
};

export const addItemToCategory = (data, targetId, newItem) => {
  // Ensure the new item has an empty or null id
  if (newItem.integrityId !== '' && newItem.integrityId !== null) {
    throw new Error('New item must have an empty or null id.');
  }

  // Helper function to recursively search and add item
  function recursiveAdd(categories) {
    for (const category of categories) {
      // If the target ID is found, add the new item to the categories array
      if (category.integrityId === targetId) {
        category.categories = category.categories || [];
        category.categories.push(newItem);
        return true; // Item added successfully
      }

      // Recursively search in nested categories
      if (category.categories && recursiveAdd(category.categories)) {
        return true;
      }
    }
    return false; // Target ID not found in this branch
  }

  // Start the recursive search and addition process
  recursiveAdd(data);

  return { data }; // Return the updated data structure and itemAdded flag
};
