import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { BasePage } from 'altus-ui-components';
import withStyles from '@material-ui/styles/withStyles';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { getIntegrityManagementIntegritiesFromState } from 'features/settings/integrityManagement/helpers/integrityManagement.selectors';
import {
  INTEGRITY_MANAGEMENT_ACTIONS,
  INTEGRITY_TYPE_ENUM,
  RISK_PRINCIPLE_TYPES,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import {
  deleteIntegrityManagementRule,
  loadIntegrityManagementRules,
} from 'features/settings/integrityManagement/helpers/integrityManagement.actions';
import { Grid } from '@material-ui/core';
import IntegrityActions from 'features/settings/integrityManagement/rules/IntegrityActions';
import { useToggle } from 'altus-hooks';
import AddRuleDrawer from 'features/settings/integrityManagement/rules/AddRuleDrawer';
import IntegritiesTable from '../rules/IntegritiesTable';
import { EMPTY_LIST } from 'app/app.constants';

const ConsequenceOfFailureContainer = ({
  dataState,
  integrities,
  dispatchLoadIntegrityManagementRules,
  // eslint-disable-next-line
  dispatchDeleteIntegrityManagementRule,
  classes,
}) => {
  const [isOpen, toggle] = useToggle(); // TODO delete when not needed

  const [filteredIntegrities, setFilteredIntegrities] = useState(EMPTY_LIST);

  const riskPrincipleType = RISK_PRINCIPLE_TYPES.CONSEQUENCE_OF_FAILURE;

  useEffect(() => {
    dispatchLoadIntegrityManagementRules();
  }, [dispatchLoadIntegrityManagementRules]);

  // maybe move into the mapper
  useEffect(() => {
    if (integrities) {
      setFilteredIntegrities(
        integrities
          ?.filter((item) => item.title === riskPrincipleType)
          ?.filter(
            (item) => item.integrityType === INTEGRITY_TYPE_ENUM.RISK_PRINCIPLE,
          ),
        // flattening the map but on the risk principle itself we can have details it seems
        // .flatMap((item) => List([item.details, item.categories]))
        // .flatten(true),
      );
    }
  }, [integrities, riskPrincipleType]);

  // we skip this for now
  // eslint-disable-next-line
  const deleteIntegrity = (integrityId, integrityType) => {
    //dispatchDeleteIntegrityManagementRule(integrityId);
  };

  return (
    <BasePage dataState={dataState}>
      <Grid item container spacing={2} className={classes.tableContainer}>
        <IntegrityActions
          toggleDrawer={toggle}
          riskPrincipleType={riskPrincipleType}
        />
        <IntegritiesTable
          integrities={filteredIntegrities}
          deleteIntegrity={deleteIntegrity}
          riskPrincipleType={riskPrincipleType}
        />
        <AddRuleDrawer isOpen={isOpen} toggleDrawer={toggle} />
      </Grid>
    </BasePage>
  );
};

const styles = (theme) => ({
  tableContainer: {
    paddingTop: theme.spacing(2),
  },
});

export default compose(
  connect(
    (state) => ({
      integrities: getIntegrityManagementIntegritiesFromState(state),
      dataState: getSummarizedDataStateFromState(
        state,
        INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES,
        INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_RULES,
        INTEGRITY_MANAGEMENT_ACTIONS.DELETE_INTEGRITY_MANAGEMENT_RULE,
      ),
    }),
    {
      dispatchLoadIntegrityManagementRules: loadIntegrityManagementRules,
      dispatchDeleteIntegrityManagementRule: deleteIntegrityManagementRule,
    },
  ),
  withStyles(styles),
)(ConsequenceOfFailureContainer);
