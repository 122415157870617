import React from 'react';
import { RuleForm } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import DateTimePickerFormik from 'app/components/form/formik/DateTimePickerFormik';

const TimeRuleDataFormComponent = () => {
  return (
    <Grid item xs={6}>
      <Field name={RuleForm.VALUE}>
        {({ form, ...formik }) => (
          <DateTimePickerFormik
            style={{ paddingTop: '3px' }}
            autoOk
            label="Default Date and Time"
            placeholder="yyyy/mm/dd hh:mm"
            form={form}
            {...formik}
          />
        )}
      </Field>
    </Grid>
  );
};

export default TimeRuleDataFormComponent;
