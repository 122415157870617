import Agent from 'infrastructure/agent';
import integrityManagementMappers from 'features/settings/integrityManagement/helpers/integrityManagement.mappers';

// These Endpoints will change according to the risk principles
const getIntegrityManagementIntegrities = () =>
  Agent.get('/integritymanagement');

const getIntegrityManagementIntegrity = (integrityId) =>
  Agent.get(`/integritymanagement/${integrityId}`);

// this should be used for all integrities
const addNewIntegrityManagementRule = (rule) =>
  Agent.post('/integritymanagement', integrityManagementMappers.rules.to(rule));

const addNewIntegrityManagementIntegrity = (integrity) =>
  Agent.post(
    '/integritymanagement',
    integrityManagementMappers.integrities.to(integrity),
  );

const updateIntegrityManagementIntegrity = (integrity) =>
  Agent.put(
    '/integritymanagement',
    integrityManagementMappers.integrities.to(integrity),
  );

const deleteIntegrityManagementRule = (ruleId) =>
  Agent.delete(`/integritymanagement/${ruleId}`);

const getIntegrityManagementIntegritiesForWell = (externalWellboreId) =>
  Agent.get(`/integritymanagement/well/${externalWellboreId}`);

const addNewIntegrityWellData = (wellboreId, integrity) =>
  Agent.post(
    '/integritymanagement/well',
    integrityManagementMappers.integrities.create(wellboreId, integrity),
  );

const integrityManagementService = {
  getIntegrityManagementIntegrities,
  getIntegrityManagementIntegrity,
  addNewIntegrityManagementRule,
  deleteIntegrityManagementRule,
  addNewIntegrityManagementIntegrity,
  updateIntegrityManagementIntegrity,
  getIntegrityManagementIntegritiesForWell,
  addNewIntegrityWellData,
};

export default integrityManagementService;
