import React from 'react';
import { Grid } from '@material-ui/core';
import CategoriesTableWeight from './CategoriesTableWeight';

const CategoryWeightContainer = ({
  integrities,
  setIntegrities,
  integrityType,
}) => (
  <Grid container justifyContent="flex-start">
    <Grid item xs={6}>
      <CategoriesTableWeight
        integrities={integrities}
        setIntegrities={setIntegrities}
        integrityType={integrityType}
      />
    </Grid>
  </Grid>
);

export default CategoryWeightContainer;
