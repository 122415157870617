import { Map } from 'immutable';
import { createTheme } from '@material-ui/core/styles';

import {
  blue,
  grey,
  green,
  orange,
  yellow,
  amber,
  lightGreen,
  purple,
  red,
  deepPurple,
  teal,
  lightBlue,
  indigo,
  blueGrey,
  lime,
} from '@material-ui/core/colors';

import { defaultOptions as defaultThemeOptions, THEMES } from 'altus-ui-theme';

import {
  ENVIRONMENTS,
  ProjectWellboreDetailStatus,
  PROJECT_STATUS as ProjectStatus,
} from 'app/app.constants';

export const defaultThemeType = THEMES.DARK;

const primary = 'rgb(16, 95, 154)';
const primaryLight = '#b7d1ed';
const primaryHover = '#244c69';
const newPrimaryLight = blue[200];
const rowColor = grey[800];
const rowHover = grey[700];
const rowSelected = primary;
const warningPrimary = '#FFA726';
const disabledGrey = '#a1a1a1';
const selection = blue[200];
const success = green[500];
const warning = yellow[500];
const error = red[500];
const unknown = grey[500];

export const defaultTheme = createTheme({
  palette: {
    type: defaultThemeType,
  },
});

const defaultDwiThemeOptions = {
  altus: {
    background: {
      header: primary,
      dashboard: '#2c2c2c',
    },
    highlight: {
      marker: primary,
    },
    action: {
      decision: blue[500],
      question: yellow[500],
    },
    statusColor: {
      [ProjectWellboreDetailStatus.DRAFT]: amber[100],
      [ProjectWellboreDetailStatus.REQUIRES_APPROVAL]: blue[200],
    },
    components: {
      TableRow: {
        background: rowColor,
        hoverColor: rowHover,
      },
      SortableListRow: {
        stripedColor: rowColor,
        background: rowColor,
        selectedColor: rowSelected,
      },
      SortableListHeader: {
        columnBackground: rowColor,
        background: rowColor,
        border: 'none',
      },
      SortableListActionsHeader: {
        background: 'transparent',
      },
      SortableListRowColumn: {
        textColor: grey[100],
      },
      ApplicationSidebar: {
        background: grey[900],
        header: {
          background: grey[900],
        },
      },
      ApplicationSidebarMenuItem: {
        background: {
          hover: grey[700],
          active: grey[700],
        },
        textColor: defaultTheme.palette.secondary.contrastText,
        iconColor: defaultTheme.palette.secondary.contrastText,
      },
      ModalContainer: {
        content: {
          padding: 25,
        },
      },
      AutocompleteTextField: {
        InputLabelColor: red[500],
      },
      RichtextEditor: {
        main: defaultTheme.palette.common.white,
        disabled: disabledGrey,
      },
      Switch: {
        disabled: disabledGrey,
      },
      ContextualizedWell: {
        tooltip: {
          background: {
            main: '#5c5c5c',
          },
        },
        trajectory: {
          depth: orange[600],
          dhsv: success,
          targetDepth: yellow[600],
          maximumWorkingDepth: red[500],
          graph: grey[800],
          water: '#0a5498',
          soil: '#ab7954',
          pipe: '#F5F5F5',
          nipple: '#8D918D',
          axisHelper: grey[500],
          base: blueGrey[100],
        },
        bha: {
          main: '#63686c',
        },
        metal: {
          main: '#919598',
          light: '#aaa9ad',
        },
      },
      SmLocation: {
        statusIndicator: {
          ping: success,
          noPing: warning,
          unknown: unknown,
          error: error,
        },
      },
      ToolstringPreview: {
        lengthSection: {
          main: amber['A700'],
        },
      },
      SimulationDashboard: {
        series: {
          yAxis: red[500],
          SurfaceWeightRIH: lightBlue[200],
          SurfaceWeightPOOH: lightBlue[500],
          SWSafeOperatingLoad: '#FF41FF',
          CompressionLimit: yellow['A200'],
          WeakPoint: purple[200],
          MaxSetDown: indigo[100],
          MaxPickupForcePOOH: indigo['A100'],
          MinTractorPull: success,
          CableHeadTensionRIH: red['A100'],
          CableHeadTensionPOOH: '#F76464',
          JarActivation: success,
          StretchRIH: '#FAD000',
          StretchPOOH: '#FA9A00',
        },
        plotBands: {
          RIH: blue[300],
          POOH: blue[800],
        },
        header: {
          checked: green[600],
        },
      },
      Dashboards: {
        metalMilling: {
          icon: green[900],
          ropMetric: '#74FA02',
        },
      },
      Mobilisation: {
        tasksMenu: {
          selected: '#0F5E99',
        },
      },
      GanttChart: {
        status: {
          plan: blue[500],
          rest: lightGreen.A400,
        },
      },
      RiskMatrix: {
        background: {
          red: error,
          yellow: lime['A100'],
          green: success,
        },
      },
    },
  },
  palette: {
    environment: {
      [ENVIRONMENTS.LOCAL]: lightGreen[500],
      [ENVIRONMENTS.DEV]: orange[500],
      [ENVIRONMENTS.TST]: yellow[500],
      [ENVIRONMENTS.DEM]: purple[500],
      [ENVIRONMENTS.TRAINING]: red[500],
    },
    action: {
      hoverOpacity: 0.5,
      selected: selection,
    },
    warning: {
      main: warningPrimary,
      light: warningPrimary,
      dark: warningPrimary,
    },
    error: {
      main: error,
    },
    success: {
      main: success,
    },
    primary: {
      main: primary,
      light: primaryLight,
      newLight: newPrimaryLight,
    },
    secondary: {
      dark: grey[900],
      darkGrey: rowColor,
      main: grey[700],
      mainLight: grey[600],
      light: grey[500],
      lighter: grey[300],
      cssGray: '#808080',
    },
    table: {
      row: {
        main: rowColor,
        hover: rowHover,
        selected: rowSelected,
      },
      header: {
        main: rowColor,
        hover: rowColor,
        selected: rowColor,
      },
    },
    tabs: {
      selected: primary,
      background: {
        main: '#303030',
        light: '#383838',
      },
    },
  },
  overrides: {
    MultiSelectModal: {
      rootContent: {
        '& .Mui-checked': {
          color: primary,
        },
      },
    },
    SortableListActionsHeader: {
      root: {
        '&:hover': {
          background: 'transparent',
        },
      },
    },
    TableHeaderRow: {
      root: {
        background: rowColor,
        borderBottom: `2px solid ${rowHover}`,
        marginBottom: defaultTheme.spacing(0.5),
      },
    },
    TableHeaderCell: {
      sortedColumn: {
        background: rowColor,
      },
    },
    TableCell: {
      root: {
        maxHeight: 'inherit',
      },
    },
    TableRow: {
      root: {
        marginBottom: defaultTheme.spacing(0.25),
        minHeight: defaultThemeOptions.altus.components.SortableListRow.height,
        maxHeight: defaultThemeOptions.altus.components.SortableListRow.height,
        '&:hover': {
          background: rowHover,
        },
        '& > &': {
          marginBottom: 0, // TODO Table component has a nested element with the same classname, making the child element have margin as well. Check if this can be fixed inside ui-core instead
        },
      },
      rootBackground: {
        backgroundColor: rowColor,
      },
      rootStriped: {
        backgroundColor: rowColor,
        '&:nth-child(even)': {
          backgroundColor: rowColor,
        },
        '&:hover': {
          background: rowHover,
        },
      },
      rootSelected: {
        backgroundColor: rowColor,
        '&:nth-child(even)': {
          backgroundColor: rowColor,
        },
      },
      rootBorder: {
        borderWidth: 0,
      },
    },
    SortableListHeader: {
      root: {
        background: rowColor,
        marginBottom: defaultTheme.spacing(0.5),
      },
      rootBorder: {
        borderBottom: `2px solid ${rowHover}`,
      },
    },
    // TODO Switch out all instances of SortableList > Table and remove this
    SortableListRow: {
      root: {
        height: 'auto',
        marginBottom: defaultTheme.spacing(0.25),
        minHeight: defaultThemeOptions.altus.components.SortableListRow.height,
        '&:hover': {
          backgroundColor: rowHover,
        },
      },
      rootBackground: {
        backgroundColor: rowColor,
      },
      rootStriped: {
        backgroundColor: rowColor,
        '&:nth-child(even)': {
          backgroundColor: rowColor,
        },
        '&:hover': {
          backgroundColor: rowHover,
        },
      },
      rootBorder: {
        borderWidth: 0,
      },
    },
    TableFilter: {
      tableRowRoot: {
        '&:hover': {
          background: defaultTheme.palette.background.paper,
        },
      },
    },
    BasePage: {
      children: {
        overflow: 'auto',
      },
    },
    NavigationTile: {
      tileRoot: {
        background: grey[900],
        '&:hover': {
          background: grey[700],
        },
      },
      tileTitle: {
        color: defaultTheme.palette.secondary.contrastText,
      },
      tileIcon: {
        color: defaultTheme.palette.secondary.contrastText,
      },
    },
    BasePageTitle: {
      titleIcon: {
        color: defaultTheme.palette.secondary.contrastText,
      },
    },
    PaperListItem: {
      expansionPanelDetailsRoot: {
        width: '100%',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
      colorDefault: {
        backgroundColor: grey[900],
        color: defaultTheme.palette.common.white,
      },
    },
    MuiTypography: {
      colorSecondary: {
        color: defaultTheme.palette.common.white,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      root: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiDialog: {
      paper: {
        background: defaultTheme.palette.background.default,
      },
    },
    MuiInputAdornment: {
      root: {
        '& .MuiTypography-root': {
          fontSize: defaultTheme.typography.caption.fontSize,
        },
      },
    },
    MuiTimelineItem: {
      missingOppositeContent: {
        '&:before': {
          flex: 0,
        },
      },
    },
    MuiLink: {
      root: {
        display: 'flex',
        color: newPrimaryLight,
        alignItems: 'center',
        '&:hover': {
          color: blue[400],
        },
      },
      underlineAlways: {
        // for some reason we need to specify 'px' here
        textUnderlineOffset: '1px',
      },
    },
    MuiToggleButton: {
      root: {
        '&.Mui-selected': {
          backgroundColor: primary,
        },
      },
    },
    MuiInputLabel: {
      root: {
        '&.Mui-focused': {
          color: newPrimaryLight,
        },
      },
    },
    MuiInputBase: {
      root: {
        '&.MuiInput-underline:after': {
          borderBottomColor: newPrimaryLight,
        },
      },
      input: {
        textOverflow: 'ellipsis',
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: () => defaultDwiThemeOptions.palette.tabs.selected,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        borderTopLeftRadius: defaultTheme.spacing(0.5),
        borderTopRightRadius: defaultTheme.spacing(0.5),
        '&.Mui-selected': {
          backgroundColor: () => defaultDwiThemeOptions.palette.tabs.selected,
        },
      },
    },
    Tab: {
      root: {
        '&.Mui-selected': {
          background: 'none',
        },
        '&::after': {
          borderBottomColor: () => defaultDwiThemeOptions.palette.tabs.selected,
        },
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: defaultTheme.palette.action.disabledBackground,
      },
      barColorPrimary: {
        backgroundColor: lightGreen.A400,
      },
    },
  },
  props: {
    MuiLink: {
      underline: 'always',
    },
    MuiCheckbox: {
      color: 'primary',
    },
    MultiSelectModal: {
      displayChips: true,
    },
  },
  mobile: {
    Background: {
      dark: '#313131',
      main: '#232323',
    },
    Drawer: {
      background: {
        main: '#2A2A2A',
        dark: '#252525',
      },
      puller: {
        main: '#505050',
      },
    },
    NavBar: {
      children: {
        main: defaultTheme.palette.common.white,
        selected: selection,
      },
    },
    Dialog: {
      content: {
        background: {
          main: grey[800],
        },
      },
      buttons: {
        close: {
          main: grey[500],
        },
      },
    },
    Icons: {
      operationStatus: {
        speed: deepPurple[300],
        depth: orange[300],
        tension: teal[300],
      },
    },
  },
};

const darkThemeOptions = {
  altus: {
    background: {
      backdrop: `${grey[900]}e0`,
      filter: grey['A400'],
      header: grey[900],
      listitem: rowColor,
      navigation: `${grey[900]}bd`,
      panel: rowColor,
    },
    // TODO Use palette.status instead
    status: {
      planning: blue[400],
      execute: lightGreen['A400'],
      report: green[600],
    },
  },
  palette: {
    type: THEMES.DARK,
    action: {
      hover: primaryHover,
      selected: primary,
    },
    status: {
      [ProjectStatus.PLAN]: blue[400],
      [ProjectStatus.EXECUTE]: lightGreen.A400,
      [ProjectStatus.REPORT]: green[600],
    },
  },
};

const lightThemeOptions = {
  altus: {
    background: {
      backdrop: `${grey[50]}e0`,
      filter: grey['A100'],
      headerTransparent: `${grey['A100']}bd`,
      header: `${grey['A100']}`,
      listitem: grey[50],
      navigation: `${grey[50]}bd`,
      panel: `${grey[100]}bd`,
    },
    // TODO Use palette.status instead
    status: {
      planning: blue[500],
      execute: lightGreen['A700'],
      report: green[600],
    },
  },
  palette: {
    type: THEMES.LIGHT,
    background: {
      default: grey[200],
    },
    status: {
      [ProjectStatus.PLAN]: blue[500],
      [ProjectStatus.EXECUTE]: lightGreen.A700,
      [ProjectStatus.REPORT]: green[600],
    },
  },
};

const mergedDefaultOptions = Map(defaultThemeOptions)
  .mergeDeep(Map(defaultDwiThemeOptions))
  .toJS();

const mergedDarkThemeOptions = Map(mergedDefaultOptions)
  .mergeDeep(Map(darkThemeOptions))
  .toJS();

const mergedLightThemeOptions = Map(mergedDefaultOptions)
  .mergeDeep(Map(lightThemeOptions))
  .toJS();

export const darkTheme = createTheme(mergedDarkThemeOptions);

export const lightTheme = createTheme(mergedLightThemeOptions);

const themes = {
  [THEMES.DARK]: darkTheme,
  [THEMES.LIGHT]: lightTheme,
};

export default themes;
